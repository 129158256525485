<template>
  <Modal :value="visible"
         :title="dataId ? '修改' + title : '新增' + title"
         width="900"
         @on-visible-change="onChangeVisible"
         class-name="vertical-center-modal">
    <div class="modal-cont">
      <ul class="form-ul">
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>作业计划：</p>
          <Input v-model="plan"
                 placeholder="作业计划"
                 maxlength="30"
                 v-stringLimit></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>网格名称：</p>
          <!-- <MyTreeSelect :value="gridName"
                        placeholder="网格名称"
                        :data="gridNameTreeArr"
                        search
                        style="width:100%;"
                        @onSelectChange="onChangeGridName">
          </MyTreeSelect> -->
		  <Select
		                  v-model="model19"
		                  filterable
		                  :remote-method="remoteMethod3"
		                  default-label="1"
		                  :loading="loading3"
		              >
		                  <Option v-for="(option, index) in gridNameTreeArr" :value="option.value" :key="index">{{option.label}}</Option>
		              </Select>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>所属机构：</p>
          <MyTreeSelect :value="departmentName"
                        placeholder="所属机构"
                        clearable
                        :data="departmentTreeArr"
                        search
                        style="width:100%"
                        @onSelectChange="onChangeDepartment">
          </MyTreeSelect>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>作业类型：</p>
          <Cascader :data="workBigTypeArr"
                    :value="workBigTypeId"
                    clearable
                    style="width:100%"
                    change-on-select
                    placeholder="作业大类"
                    @on-change="onChangeWorkType"></Cascader>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>作业车辆：</p>
          <!-- 固定作业为单选，机动作业为多选 -->
          <MyTreeSelect :value="car"
                        placeholder="作业车辆"
                        :disabled='carDisabled'
                        :data="carTreeArr"
                        search
                        :checked="operationMode == 1"
                        style="width:100%"
                        @onSelectChange="onChangeSelectCar"
                        @onCheckChange="onChangeChangeCar">
          </MyTreeSelect>

          <!-- <Input v-model="driver"
                 placeholder="驾驶员"
                 readonly /> -->
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>作业班组：</p>
          <MyTreeSelect :value="group"
                        placeholder="班组名称"
                        :data="groupTreeArr"
                        search
                        style="width:100%;"
                        @onSelectChange="onChangeGroup">
          </MyTreeSelect>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">作业规则：</p>
          <Select v-model="ruleId"
                  label-in-value
                  @on-change="onChangeRule"
                  clearable>
            <Option v-for="item in ruleArr"
                    :value="item.workRuleId"
                    :key="item.workRuleId">{{ item.ruleName }}</Option>
          </Select>
          <!-- <Input v-model="rule"
                 placeholder="作业规则"></Input> -->
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>作业周期：</p>
          <Select v-model="period"
                  placeholder="作业周期"
                  class="input-right-5">
            <Option :value="item.id"
                    v-for="item in periodArr"
                    :key="item.id">{{item.name}}
            </Option>
          </Select>
          <Poptip trigger="hover"
                  content="计划运行周期"
                  placement="bottom">
            <Icon custom="i-icon icon-tishi1"
                  size="22"
                  color="#25bb96"
                  class="cursor-pointer" />
          </Poptip>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>趟次：</p>
          <Input v-model="count"
                 placeholder="趟次"
                 maxlength="30"
                 v-intLimit
                 class="input-right-5"></Input>
          <span class="li-unit m-r-10">次</span>
          <Poptip trigger="hover"
                  content="单个考勤段内需作业趟次"
                  placement="bottom">
            <Icon custom="i-icon icon-tishi1"
                  size="22"
                  color="#25bb96"
                  class="cursor-pointer" />
          </Poptip>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">单次预计油耗：</p>
          <Input v-model="oilLoss"
                 placeholder="单次预计油耗"
                 maxlength="30"
                 v-floatLimit
                 clearable
                 class="input-right-5"></Input>
          <span class="li-unit m-r-10">L</span>
          <Poptip trigger="hover"
                  content="单个考勤段内预计用油量"
                  placement="bottom">
            <Icon custom="i-icon icon-tishi1"
                  size="22"
                  color="#25bb96"
                  class="cursor-pointer" />
          </Poptip>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">单次预计水耗：</p>
          <Input v-model="waterLoss"
                 placeholder="单次预计水耗"
                 maxlength="30"
                 v-floatLimit
                 clearable
                 class="input-right-5"></Input>
          <span class="li-unit m-r-10">T</span>
          <Poptip trigger="hover"
                  content="单个考勤段内预计用水量"
                  placement="bottom">
            <Icon custom="i-icon icon-tishi1"
                  size="22"
                  color="#25bb96"
                  class="cursor-pointer" />
          </Poptip>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">单次作业时长：</p>
          <Input v-model="workTime"
                 placeholder="单次作业时长"
                 maxlength="30"
                 v-intLimit
                 clearable
                 class="input-right-5"></Input>
          <span class="li-unit m-r-10">分钟</span>
          <Poptip trigger="hover"
                  content="单个考勤段内作业时长"
                  placement="bottom">
            <Icon custom="i-icon icon-tishi1"
                  size="22"
                  color="#25bb96"
                  class="cursor-pointer" />
          </Poptip>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>有效时段：</p>
          <DatePicker type="date"
                      class="half"
                      style="margin-right:0"
                      :value="startTime"
                      :options="dateOptions"
                      :disabled='startDateDisabled'
                      :editable=false
                      @on-change="onChangeStart"
                      placeholder=""></DatePicker>
          <span style="margin: 0 10px;line-height:32px;">~</span>
          <DatePicker type="date"
                      class="half"
                      :value="endTime"
                      :options="dateOptions"
                      :disabled='endDateDisabled'
                      :editable=false
                      @on-change="onChangeEnd"
                      placeholder=""></DatePicker>
        </li>
        <li class="form-ul-li">
          <p class="li-title">备注：</p>
          <Input v-model="remark"
                 maxlength="-1"
                 :rows="3"
                 type="textarea"
                 placeholder="" />
        </li>
      </ul>

      <div class="title-tip">
        <Icon custom="i-icon icon-jingshi"
              size="15"
              color="red"
              class="m-r-5"
              style="line-height: 14px;" />
        当前平台设置为{{summerTime}}至{{winterTime}}为夏季作息时间，{{winterTime}}为冬季作息时间
        <p @click.stop="onClickTime">设置冬夏时间</p>
      </div>

    </div>
    <div slot="footer">
      <Button @click.stop="onClickCancel">取消</Button>
      <Button type="primary"
              :loading="loading"
              @click.stop="onClickConfirm">确定</Button>
    </div>

    <!-- 设置冬夏时间 -->
    <WorkTimeModal v-model="workTimeVisible"
                   :dataId.sync="workTimeId"
                   @onClickConfirm="getTimeDetail"></WorkTimeModal>
  </Modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import MyTreeSelect from '@/components/common/MyTreeSelect';
import WorkTimeModal from '@/components/product/admin/modal/work/WorkTimeModal'
import { getTomorrow, formatDate } from '@/utils/dateRangUtil'
export default {
  components: {
    MyTreeSelect,
    WorkTimeModal
  },
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: '排班'
    },
    dataId: String,
    workType: Number
  },
  data () {
    return {
      dateOptions: {
        disabledDate (date) {
          // return date && date.valueOf() < Date.now()
          return false
        }
      },
      startDateDisabled: false,
      endDateDisabled: false,
      loading: false,
      carDisabled: true,
      plan: '', // 作业计划
      gridName: '', // 网格名称
      gridNameId: '', // 网格名称id
      workBigType: [], // 作业类型
      workBigTypeId: [], // 作业类型
      car: '', // 作业车辆
      carId: '', // 作业车辆id
      driver: '', // 驾驶员
      driverId: '', // 驾驶员id
      group: '', // 作业班组
      groupId: '', // 作业班组
      rule: '', // 作业规则
      ruleId: '', // 作业规则
      period: '', // 作业周期
      count: '2', // 趟次
      oilLoss: '', // 油耗
      waterLoss: '', // 水耗
      workTime: '', // 作业时长
      startTime: '', // 有效时段
      endTime: '', // 有效时段
      remark: '', // 备注
      status: 1, // 启用状态
      // 车辆树列表
      departmentName: '',
      departmentId: '',
      operationMode: '',
      summerTime: '',
      winterTime: '',
      typeArr: [],
      periodArr: [
        {
          id: 1,
          name: '每天'
        },
        {
          id: 2,
          name: '两天'
        },
        {
          id: 3,
          name: '三天'
        },
        {
          id: 7,
          name: '一周'
        },
      ],
      carTreeArr: [],
      driverTreeArr: [],
      groupTreeArr: [],
      ruleArr: [],
      workBigTypeArr: [
        {
          children: [],
          id: '74468e83218d866cb341232ffae4618a',
          itemlist: [],
          label: '清扫作业',
          name: '清扫作业',
          type: 'car',
          value: '74468e83218d866cb341232ffae4618a',
        },
        {
          children: [],
          id: '53616f89f4238e20b108eae870a8cdb2',
          itemlist: [],
          label: '洒水作业',
          name: '洒水作业',
          type: 'car',
          value: '53616f89f4238e20b108eae870a8cdb2',
        },
      ],
      // 设置冬夏时间
      workTimeVisible: false,
      workTimeId: null,
	  model19: '',
	  options3: [],
	  loading3: false,
    }
  },
  watch: {
    dataId (newVal) {
      newVal && this.getDetail()
    },
    workType (newVal) {
      if (newVal) {
        this.operationMode = newVal
      }
    },
    visible (newVal) {
      if (newVal) {
        this.getGroupList()
        this.getRuleList()
      }
    }
  },
  computed: {
    ...mapGetters({
      'departmentTreeArr': 'getDepartmentTreeList',
    }),
	gridNameTreeArr(){
		this.options3 = []
		return this.format(this.$store.getters.getGridTreeEnableList,true)
	}
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapActions([
      // 'updateGridTreeList',
      'updateDepartmentTreeList',
      'updateGridTreeEnableList'
    ]),
    init () {
      // this.updateGridTreeList()
      this.updateGridTreeEnableList()
      this.updateDepartmentTreeList()
      this.getTimeDetail()
      this.timeInit()
    },
	format(list,type){
		list.forEach(item=>{
			if(item.children){
				this.format(item.children)
			}
			if(item.type=='1'||item.type=='2'||item.type=='3'){
				this.options3.push({
					id:item.id,
					label:item.name,
					value:item.name
				})
			}
		})
		return type?this.options3:''
	},
	remoteMethod3 (query) {
		for (var i = 0; i < this.gridNameTreeArr.length; i++) {
			if(this.gridNameTreeArr[i].value==query){
				this.gridName = query;
				this.gridNameId = this.gridNameTreeArr[i].id;
				break;
			}
		}
	},
    // 获取详情
    getDetail () {
      this.$store.dispatch('getMechanicalCleanPlanDetail', {
        carPlanId: this.dataId,
        type: 1
      }).then(res => {
        for (let i in res) {
          this[i] = res[i]
        }
		this.model19 = res.gridName
        this.carId = res.carId.join()
        this.car = res.car.join()
        this.carDisabled = false
        this.workBigTypeId = [res.workTypeId, res.workSubTypeId]
        this.workBigType = [res.workTypeName, res.workSubTypeName]
        if (!this.isBeyondDate(this.startTime)) {
          this.startDateDisabled = true
        }
        if (!this.isBeyondDate(this.endTime)) {
          this.endDateDisabled = true
        }
        // this.startTime = formatDate(res.startTime)
        // 获取车辆列表
        this.getCarTreeList([res.workTypeId, res.workSubTypeId])
      })
    },
    // 日期选择器赋初始值
    timeInit () {
      let time = new Date()
      // this.startTime = time.getFullYear() + '-01-01'
      this.startTime = getTomorrow()
      this.endTime = time.getFullYear() + '-12-31'
    },
    // 是否超出日期
    isBeyondDate (date) {
      return formatDate(new Date()) < date
    },
    // 获取冬夏时间
    getTimeDetail () {
      this.$store.dispatch('getTimeCompleteDetail', 3).then(res => {
        let summer = res.summerDate.split('-')
        this.summerTime = summer[0] + '月' + summer[1] + '日'
        let winter = res.winterDate.split('-')
        this.winterTime = winter[0] + '月' + winter[1] + '日'
      })
    },
    // 点击设置冬夏时间
    onClickTime () {
      this.workTimeVisible = true
      this.workTimeId = 3
    },
    // 选择网格
    onChangeGridName (section) {
      if (section.type != 'depart') {
        this.gridName = section.name
        this.gridNameId = section.id
      }
    },
    // 修改所属机构
    onChangeDepartment (section) {
      this.carId = ''
      this.car = ''
      this.driverId = ''
      this.driver = ''
      this.departmentId = section.id
      this.departmentName = section.name
      if (this.workBigTypeId.length != 0) {
        // 获取车辆列表
        this.carDisabled = false
        this.getCarTreeList()
      }
    },
    // 作业类型选择器回调，获取车辆列表
    onChangeWorkType (value) {
      this.carId = ''
      this.car = ''
      this.driverId = ''
      this.driver = ''
      let index = this.workBigTypeArr.findIndex(item => {
        return item.id == value[0]
      })
      this.workBigType = [this.workBigTypeArr[index].label]
      this.workBigTypeId = [this.workBigTypeArr[index].value]
      if (value.length == 2) {
        let subIndex = this.workBigTypeArr[index].children(item => {
          return item.id == value[1]
        })
        this.workBigType.push(this.workBigTypeArr[index].children[subIndex].label)
        this.workBigTypeId.push(this.workBigTypeArr[index].children[subIndex].value)
      }
      if (this.departmentId) {
        // 获取车辆列表
        this.carDisabled = false
        this.getCarTreeList()
      }
    },
    // 获取车辆列表
    getCarTreeList () {
      let params = {
        carcategory: this.workBigTypeId[0],
        carcategoryitem: this.workBigTypeId[1] ? this.workBigTypeId[1] : '',
        depid: this.departmentId
      }
      this.$http.getCarDetailTreeList(params).then(res => {
        if (res.code === 200) {
          this.carTreeArr = res.result
        }
      })
    },
    // 选择车辆 多选
    onChangeChangeCar (section) {
      let idArr = []
      let valueArr = []
      let driverIdArr = []
      let driverValueArr = []
      section.forEach(item => {
        if (item.type === 'car') {
          idArr.push(item.id)
          valueArr.push(item.name)
          driverIdArr.push(item.bindaccount)
          driverValueArr.push(item.bindaccountname)
        }
      })
      this.carId = idArr.join()
      this.car = valueArr.join()
      this.driverId = driverIdArr.filter(item => item).join()
      this.driver = driverValueArr.filter(item => item).join()
    },
    // 选择车辆 单选
    onChangeSelectCar (section) {
      if (this.operationMode == 2) {
        if (section.type == 'car') {
          this.carId = section.id
          this.car = section.name
          this.driverId = section.bindaccount
          this.driver = section.bindaccountname
        }
      }
    },
    // 获取作业班组列表
    getGroupList () {
      let params = {
        module: 3
      }
      this.$http.getWorkGroupItemAllList(params).then(res => {
        if (res.code === 200) {
          this.groupTreeArr = res.result.map(item => {
            item.name = item.depName
            item.id = item.depid
            item.type = 'depart'
            if (item.children) {
              item.children = item.children.map(items => {
                items.type = 'group'
                return items
              })
            }
            return item
          })
        }
      })
    },
    // 选择班组
    onChangeGroup (section) {
      if (section.type == 'group') {
        this.groupId = section.workGroupId
        this.group = section.name
      }
    },
    // 获取作业规则列表
    getRuleList () {
      let params = {
        page: 1,
        size: 999,
        module: 3
      }
      this.$http.getWorkRuleList(params).then(res => {
        if (res.code === 200) {
          this.ruleArr = res.result.workRules
        }
      })
    },
    // 作业规则回调
    onChangeRule (data) {
      if (data) {
        this.rule = data.label
        this.ruleId = data.value
      }
    },
    // 有效时段开始时间
    onChangeStart (value) {
      this.startTime = value
    },
    // 有效时段结束时间
    onChangeEnd (value) {
      this.endTime = value
    },
    // 点击确认
    onClickConfirm () {
      if (!this.plan) return this.$Message.info('请输入作业计划');
      if (!this.gridName) return this.$Message.info('请选择网格');
      if (this.workBigType.length == 0) return this.$Message.info('请选择作业类型');
      if (!this.car) return this.$Message.info('请选择作业车辆');
      if (!this.group) return this.$Message.info('请选择作业班组');
      if (!this.period) return this.$Message.info('请输入作业周期');
      if (!this.count) return this.$Message.info('请输入趟次');
      if (!this.startTime) return this.$Message.info('请选择有效时段');
      if (!this.endTime) return this.$Message.info('请选择有效时段');
      if (this.startTime > this.endTime) return this.$Message.info('请选择正确的有效时段')
      this.loading = true
      if (!this.dataId) {
        let repeatName = {
          planName: this.plan,
          gridIdList: [this.gridNameId],
          module: 2,
          type: 1
        }
        this.$http.repeatCarPlanName(repeatName).then((res) => {
          if (res.result.length) {
            this.loading = false
            this.$Message.info({
              content: '作业计划名称重复'
            })
          } else {
            // 创建
            this.createPlan()
          }
        })
      } else {
        this.createPlan()
      }
    },
    createPlan () {
      let params = {
        plan: this.plan,
        // gridName: this.gridName,
        // workTypeName: this.workBigType[0],
        workTypeId: this.workBigTypeId[0],
        // workSubTypeName: this.workBigType[1] ? this.workBigType[1] : '',
        // workSubTypeId: this.workBigTypeId[1] ? this.workBigTypeId[1] : '',
        // car: this.car,
        carId: this.carId.split(','),
        // driver: this.driver,
        // driverId: this.driverId,
        // group: this.group,
        groupId: this.groupId,
        // rule: this.rule,
        ruleId: this.ruleId,
        period: this.period,
        count: this.count,
        oilLoss: this.oilLoss,
        waterLoss: this.waterLoss,
        workTime: this.workTime,
        startTime: this.startTime,
        endTime: this.endTime,
        remark: this.remark,
        operationMode: this.operationMode,
        status: this.status,
        departmentId: this.departmentId,
        type: 1,
      }
      if (this.dataId) {
        params.carPlanIdList = [this.dataId]
        params.gridNameId = this.gridNameId
        params.flag = 2
      } else {
        params.gridNameIdList = [this.gridNameId]
        params.module = 2
      }
      this.$store.dispatch('createOrEditMechanicalCleanPlan', params).then(res => {
        this.$emit('onClickConfirm', params)
        this.$emit('onChange', false)
        // 更新下拉框
        // this.$store.dispatch('updateCarManufacturerAllList', true)
        this.loading = false
      }).catch(err => {
        this.loading = false
      })
    },
    // 点击取消
    onClickCancel () {
      this.$emit('onClickCancel')
      this.$emit('onChange', false)
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.resetData()
        this.$emit('onChange', false)
        this.$emit('update:dataId', '')
        this.$emit('update:workType', null)
      }
    },
    resetData () {
		this.model19 = ''
      this.plan = ''
      this.gridName = ''
      this.gridNameId = ''
      this.workBigType = []
      this.workBigTypeId = []
      this.car = ''
      this.carId = ''
      this.driver = ''
      this.driverId = ''
      this.group = ''
      this.groupId = ''
      this.rule = ''
      this.ruleId = ''
      this.period = ''
      this.count = 2
      this.oilLoss = ''
      this.waterLoss = ''
      this.workTime = ''
      this.remark = ''
      this.status = 1
      this.operationMode = ''
      this.departmentName = ''
      this.departmentId = ''
      this.timeInit()
      this.carDisabled = true
      this.startDateDisabled = false
      this.endDateDisabled = false
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-cont {
  max-height: none;
}
.title-tip {
  line-height: 40px;
  font-size: 12px;
  color: #6c6d6e;
  background-color: #fafbfc;
  display: flex;
  align-items: center;
  padding-left: 10px;
  p {
    margin-left: 10px;
    color: #0079fe;
    cursor: pointer;
  }
}
</style>
