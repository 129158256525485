<template>
  <Modal :value="visible"
         :title="title"
         @on-visible-change="onChangeVisible"
         width="780">
    <div class="flex ">
      <div class="menu">
        <div v-for="(item,index) in menuArr"
             :key='index'
             class="cursor-pointer"
             :class="{'current-menu':currentIndex == index}"
             @click.stop="onClickMenuItem(index)">
          {{item.name}}
        </div>
      </div>
      <div class="item-menu"
           v-if="currentIndex == 1 && workType != 2">
        <div class=" cursor-pointer"
             v-for="(item,index) in itemMenuArr"
             :key='index'
             :class="{'current-menu':currentItemIndex == index}"
             @click.stop="onClickItemMenuItem(index)">{{item.name}}
        </div>
      </div>
      <div class="modal-cont flex-1">
        <ul class="form-ul">
          <li class="form-ul-li half"
              v-if="currentIndex == 0">
            <p class="li-title">作业班组：</p>
            <MyTreeSelect :value="group"
                          placeholder="班组名称"
                          :data="groupTreeArr"
                          search
                          style="width:100%;"
                          @onSelectChange="onChangeGroup">
            </MyTreeSelect>
          </li>
          <li class="form-ul-li half"
              v-if="currentIndex == 1">
            <p class="li-title">作业类型：</p>
            <Cascader :data="workBigTypeArr"
                      :value="workBigTypeId"
                      clearable
                      style="width:100%"
                      change-on-select
                      placeholder="作业大类"
                      @on-change="onChangeWorkType"></Cascader>
          </li>
          <li class="form-ul-li half"
              v-if="currentIndex == 1">
            <p class="li-title">所属机构：</p>
            <MyTreeSelect :value="departmentName"
                          placeholder="所属机构"
                          clearable
                          :data="departmentTreeArr"
                          search
                          style="width:100%"
                          @onSelectChange="onChangeDepartment">
            </MyTreeSelect>
          </li>
          <li class="form-ul-li"
              v-if="currentIndex == 1">
            <p class="li-title">作业车辆：</p>
            <!-- 固定作业为单选，机动作业为多选 -->
            <MyTreeSelect ref="carTreeSelect"
                          :value="car"
                          placeholder="作业车辆"
                          :disabled='carDisabled'
                          :data="carTreeArr"
                          search
                          :checked="operationMode == 1"
                          style="width:100%;"
                          @onSelectChange="onChangeSelectCar"
                          @onCheckChange="onChangeChangeCar">
            </MyTreeSelect>
          </li>
          <div class="car"
               v-if="currentIndex == 1">
            <div class="car-item"
                 v-for="(item,index) in carList"
                 :key="index"
                 @click.stop="">
              <span class="line1">{{item.name}}</span>
              <Icon custom="i-icon icon-guanbi"
                    size="16"
                    color="#6a6b75"
                    @click.stop="onClickDeleteCar(index)" />
            </div>
          </div>
          <li class="form-ul-li half"
              v-if="currentIndex == 2">
            <p class="li-title">作业规则：</p>
            <Select v-model="ruleId"
                    label-in-value
                    @on-change="onChangeRule"
                    clearable>
              <Option v-for="item in ruleArr"
                      :value="item.workRuleId"
                      :key="item.workRuleId">{{ item.ruleName }}</Option>
            </Select>
          </li>
          <li class="form-ul-li half"
              v-if="currentIndex == 3">
            <p class="li-title">作业周期：</p>
            <Select v-model="period"
                    placeholder="作业周期"
                    class="input-right-5">
              <Option :value="item.id"
                      v-for="item in periodArr"
                      :key="item.id">{{item.name}}
              </Option>
            </Select>
            <Poptip trigger="hover"
                    content="计划运行周期"
                    placement="bottom">
              <Icon custom="i-icon icon-tishi1"
                    size="22"
                    color="#25bb96"
                    class="cursor-pointer" />
            </Poptip>
          </li>
          <li class="form-ul-li half"
              v-if="currentIndex == 4">
            <p class="li-title">趟次：</p>
            <Input v-model="count"
                   placeholder="趟次"
                   maxlength="30"
                   v-intLimit
                   class="input-right-5"></Input>
            <span class="li-unit m-r-10">次</span>
            <Poptip trigger="hover"
                    content="单个考勤段内需作业趟次"
                    placement="bottom">
              <Icon custom="i-icon icon-tishi1"
                    size="22"
                    color="#25bb96"
                    class="cursor-pointer" />
            </Poptip>
          </li>
          <li class="form-ul-li half"
              v-if="currentIndex == 5">
            <p class="li-title">单次预计油耗：</p>
            <Input v-model="oilLoss"
                   placeholder="单次预计油耗"
                   maxlength="30"
                   v-floatLimit
                   clearable
                   class="input-right-5"></Input>
            <span class="li-unit m-r-10">L</span>
            <Poptip trigger="hover"
                    content="单个考勤段内预计用油量"
                    placement="bottom">
              <Icon custom="i-icon icon-tishi1"
                    size="22"
                    color="#25bb96"
                    class="cursor-pointer" />
            </Poptip>
          </li>
          <li class="form-ul-li half"
              v-if="currentIndex == 6">
            <p class="li-title">单次预计水耗：</p>
            <Input v-model="waterLoss"
                   placeholder="单次预计水耗"
                   maxlength="30"
                   v-floatLimit
                   clearable
                   class="input-right-5"></Input>
            <span class="li-unit m-r-10">T</span>
            <Poptip trigger="hover"
                    content="单个考勤段内预计用水量"
                    placement="bottom">
              <Icon custom="i-icon icon-tishi1"
                    size="22"
                    color="#25bb96"
                    class="cursor-pointer" />
            </Poptip>
          </li>
          <li class="form-ul-li half"
              v-if="currentIndex == 7">
            <p class="li-title">单次作业时长：</p>
            <Input v-model="workTime"
                   placeholder="单次作业时长"
                   maxlength="30"
                   v-intLimit
                   clearable
                   class="input-right-5"></Input>
            <span class="li-unit m-r-10">分钟</span>
            <Poptip trigger="hover"
                    content="单个考勤段内作业时长"
                    placement="bottom">
              <Icon custom="i-icon icon-tishi1"
                    size="22"
                    color="#25bb96"
                    class="cursor-pointer" />
            </Poptip>
          </li>
          <li class="form-ul-li"
              v-if="currentIndex == 8">
            <p class="li-title">有效时段：</p>
            <DatePicker type="date"
                        class="half"
                        disabled
                        style="margin-right:0"
                        :value="startTime"
                        :options="dateOptions"
                        :editable=false
                        @on-change="onChangeStart"
                        placeholder=""></DatePicker>
            <span style="margin: 0 10px;line-height:32px;">~</span>
            <DatePicker type="date"
                        class="half"
                        :value="endTime"
                        :options="dateOptions"
                        :disabled='endDateDisabled'
                        :editable=false
                        @on-change="onChangeEnd"
                        placeholder=""></DatePicker>
          </li>
          <li class="form-ul-li"
              v-if="currentIndex == 9">
            <p class="li-title">备注：</p>
            <Input v-model="remark"
                   maxlength="-1"
                   :rows="3"
                   type="textarea"
                   placeholder="" />
          </li>
        </ul>
        <div class="flex j-center a-center">
          <Icon type="ios-alert-outline"
                size="20"
                color="#FF0000"
                class="m-r-5" />
          当前批量操作的原始信息将不显示，进行操作后将修改为相同内容。
        </div>
      </div>
    </div>
    <div slot="footer">
      <Button @click.stop="onClickCancel">取消</Button>
      <Button type="primary"
              :loading="loading"
              @click.stop="onClickConfirm">确定</Button>
    </div>
  </Modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import MyTreeSelect from '@/components/common/MyTreeSelect';
import WorkTimeModal from '@/components/product/admin/modal/work/WorkTimeModal'
import { getTomorrow, formatDate } from '@/utils/dateRangUtil'
export default {
  components: {
    MyTreeSelect,
    WorkTimeModal
  },
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    title: {
      type: String,
      default: '批量修改排班'
    },
    visible: Boolean,
    dataId: String,
    workType: Number
  },
  data () {
    return {
      loading: false,
      menuArr: [
        {
          name: '作业班组'
        },
        {
          name: '作业车辆'
        },
        {
          name: '作业规则'
        },
        {
          name: '作业周期'
        },
        {
          name: '趟次'
        },
        {
          name: '单次预计油耗'
        }, {
          name: '单次预计水耗'
        },
        {
          name: '单次作业时长'
        }, {
          name: '有效时段'
        }, {
          name: '备注'
        }
      ],
      currentIndex: 0,
      itemMenuArr: [
        {
          name: '增加车辆'
        }, {
          name: '删除车辆'
        }
      ],
      currentItemIndex: 0,
      dateOptions: {
        disabledDate (date) {
          return date && date.valueOf() < Date.now()
        }
      },
      carDisabled: true,
      endDateDisabled: false,
      type: '',   // 固定作业，机动作业
      workBigType: [], // 作业类型
      workBigTypeId: [], // 作业类型
      departmentName: '',
      departmentId: '',
      car: '', // 作业车辆
      carId: '', // 作业车辆id
      driver: '', // 驾驶员
      driverId: '', // 驾驶员id
      carList: [],        // 车辆列表
      carAddList: [],     // 新增车辆列表
      carDeleteList: [],  // 删除车辆列表
      group: '', // 作业班组
      groupId: '', // 作业班组
      rule: '', // 作业规则
      ruleId: '', // 作业规则
      period: '', // 作业周期
      count: '2', // 趟次
      oilLoss: '', // 油耗
      waterLoss: '', // 水耗
      workTime: '', // 作业时长
      startTime: '', // 有效时段
      endTime: '', // 有效时段
      remark: '', // 备注
      operationMode: '',
      carTreeArr: [],
      periodArr: [
        {
          id: 1,
          name: '每天'
        },
        {
          id: 2,
          name: '两天'
        },
        {
          id: 3,
          name: '三天'
        },
        {
          id: 7,
          name: '一周'
        },
      ],
      ruleArr: [],
      groupTreeArr: [],
      workBigTypeArr: [
        {
          children: [],
          id: '74468e83218d866cb341232ffae4618a',
          itemlist: [],
          label: '清扫作业',
          name: '清扫作业',
          type: 'car',
          value: '74468e83218d866cb341232ffae4618a',
        },
        {
          children: [],
          id: '53616f89f4238e20b108eae870a8cdb2',
          itemlist: [],
          label: '洒水作业',
          name: '洒水作业',
          type: 'car',
          value: '53616f89f4238e20b108eae870a8cdb2',
        },
      ],
    }
  },
  watch: {
    dataId (newVal) {
      newVal && this.getDetail()
    },
    visible (newVal) {
      if (newVal) {
        this.getGroupList()
        this.getRuleList()
      }
    },
    workType (newVal) {
      if (newVal) {
        this.operationMode = newVal
      }
    },
    currentItemIndex () {
      this.carList = []
      this.carId = ''
      this.car = ''
    }
  },
  computed: {
    ...mapGetters({
      'gridNameTreeArr': 'getGridTreeList',
      departmentTreeArr: 'getDepartmentTreeList',
      // carTreeArr: "getCarTreeList"
    }),
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapActions([
      'updateGridTreeList',
      'updateDepartmentTreeList'
    ]),
    init () {
      this.updateGridTreeList()
      this.updateDepartmentTreeList()
      this.timeInit()
      this.getCarTreeList()
    },
    getDetail () {
      return
      this.$store.dispatch('getMechanicalCleanPlanDetail', this.dataId).then(res => {
        for (let i in res) {
          this[i] = res[i]
        }
        this.carDisabled = false
        this.workBigTypeId = [res.workTypeId, res.workSubTypeId]
        this.workBigType = [res.workTypeName, res.workSubTypeName]
        if (!this.isBeyondDate(this.startTime)) {
          this.startDateDisabled = true
        }
        if (!this.isBeyondDate(this.endTime)) {
          this.endDateDisabled = true
        }
        // this.startTime = formatDate(res.startTime)
        // 获取车辆列表
        this.getCarTreeList([res.workTypeId, res.workSubTypeId])
      })
      // this.$store.dispatch('getCarDetail', this.dataId).then(res => {
      //   for (let i in res) {
      //     this[i] = res[i]
      //   }
      // })
    },
    onClickMenuItem (i) {
      this.currentIndex = i
    },
    onClickItemMenuItem (index) {
      this.currentItemIndex = index
    },
    // 修改所属机构
    onChangeDepartment (section) {
      this.carId = ''
      this.car = ''
      this.driverId = ''
      this.driver = ''
      this.departmentId = section.id
      this.departmentName = section.name
      if (this.workBigTypeId.length != 0) {
        // 获取车辆列表
        this.carDisabled = false
        this.getCarTreeList()
      }
    },
    // 作业类型选择器回调，获取车辆列表
    onChangeWorkType (value) {
      this.carId = ''
      this.car = ''
      this.driverId = ''
      this.driver = ''
      let index = this.workBigTypeArr.findIndex(item => {
        return item.id == value[0]
      })
      this.workBigType = [this.workBigTypeArr[index].label]
      this.workBigTypeId = [this.workBigTypeArr[index].value]
      if (value.length == 2) {
        let subIndex = this.workBigTypeArr[index].children(item => {
          return item.id == value[1]
        })
        this.workBigType.push(this.workBigTypeArr[index].children[subIndex].label)
        this.workBigTypeId.push(this.workBigTypeArr[index].children[subIndex].value)
      }
      if (this.departmentId) {
        // 获取车辆列表
        this.carDisabled = false
        this.getCarTreeList()
      }
    },
    // 获取车辆列表
    getCarTreeList () {
      let params = {
        carcategory: this.workBigTypeId[0],
        carcategoryitem: this.workBigTypeId[1] ? this.workBigTypeId[1] : '',
        depid: this.departmentId
      }
      this.$http.getCarDetailTreeList(params).then(res => {
        if (res.code === 200) {
          this.carTreeArr = res.result
        }
      })
    },
    // 选择车辆 多选
    onChangeChangeCar (section) {
      let idArr = []
      let valueArr = []
      let driverIdArr = []
      let driverValueArr = []
      section.forEach(item => {
        if (item.type === 'car') {
          idArr.push(item.id)
          valueArr.push(item.name)
          driverIdArr.push(item.bindaccount)
          driverValueArr.push(item.bindaccountname)
        }
      })
      this.carId = idArr.join()
      this.car = valueArr.join()
      this.driverId = driverIdArr.filter(item => item).join()
      this.driver = driverValueArr.filter(item => item).join()
      let dataArr = []
      section.forEach((item) => {
        if (item.type === "car") {
          dataArr.push(item)
        }
      })
      dataArr.forEach(t => {
        let isFind = false;
        for (let i = 0; i < this.carList.length; i++) {
          if (t.id == this.carList[i].id) {
            isFind = true;
            break;
          }
        }
        if (!isFind) {
          this.carList.push(t);
        }
      })
    },
    // 选择车辆
    onChangeSelectCar (section) {
      if (this.operationMode == 2) {
        if (section.type == 'car') {
          this.carId = section.id
          this.car = section.name
          this.driverId = section.bindaccount
          this.driver = section.bindaccountname
          this.carList = [{
            id: section.id,
            name: section.name
          }]
        }
      }
    },
    // 删除车
    onClickDeleteCar (index) {
      this.carList.splice(index, 1)
      this.car = this.carList.map(item => {
        return item.name
      }).join()
      this.carId = this.carList.map(item => {
        return item.id
      }).join()
    },
    // 获取作业班组列表
    getGroupList () {
      let params = {
        module: 3
      }
      this.$http.getWorkGroupItemAllList(params).then(res => {
        if (res.code === 200) {
          this.groupTreeArr = res.result.map(item => {
            item.name = item.depName
            item.id = item.depid
            item.type = 'depart'
            if (item.children) {
              item.children = item.children.map(items => {
                items.type = 'group'
                return items
              })
            }
            return item
          })
        }
      })
    },
    // 选择班组
    onChangeGroup (section) {
      if (section.type == 'group') {
        this.groupId = section.workGroupId
        this.group = section.name
      }
    },
    // 获取作业规则列表
    getRuleList () {
      let params = {
        page: 1,
        size: 999,
        module: 3
      }
      this.$http.getWorkRuleList(params).then(res => {
        if (res.code === 200) {
          this.ruleArr = res.result.workRules
        }
      })
    },
    // 作业规则回调
    onChangeRule (data) {
      if (data) {
        this.rule = data.label
        this.ruleId = data.value
      }
    },
    // 有效时段开始时间
    onChangeStart (value) {
      this.startTime = value
    },
    // 有效时段结束时间
    onChangeEnd (value) {
      this.endTime = value
    },
    // 日期选择器赋初始值
    timeInit () {
      let time = new Date()
      // this.startTime = time.getFullYear() + '-01-01'
      this.startTime = getTomorrow()
      this.endTime = time.getFullYear() + '-12-31'
    },
    // 确定按钮
    onClickConfirm () {
      let carIds = [];
      this.carList.forEach((item) => {
        carIds.push(item.id);
      });
      this.loading = true
      let params = {
        type: 1
      }
      if (this.dataId) params.carPlanIdList = this.dataId.split(',')
      switch (this.currentIndex) {
        case 0:
          params.groupId = this.groupId
          break;
        case 1:
          params.departmentId = this.departmentId
          params.workTypeId = this.workBigTypeId[0]
          params.carId = carIds
          params.flag = this.workType == 2 ? 2 : this.currentItemIndex
          break;
        case 2:
          params.ruleId = this.ruleId
          break;
        case 3:
          params.period = this.period
          break;
        case 4:
          params.count = this.count
          break;
        case 5:
          params.oilLoss = this.oilLoss
          break;
        case 6:
          params.waterLoss = this.waterLoss
          break;
        case 7:
          params.workTime = this.workTime
          break;
        case 8:
          params.startTime = this.startTime
          params.endTime = this.endTime
          break;
        case 9:
          params.remark = this.remark
          break;
      }
      this.$store.dispatch('createOrEditMechanicalCleanPlan', params).then(res => {
        this.$emit('onClickConfirm', params)
        this.$emit('onChange', false)
        this.loading = false
      }).catch(err => {
        this.loading = false
      })
    },
    onClickCancel () {
      this.$emit('onClickCancel')
      this.$emit('onChange', false)
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.$emit('onChange', false)
        this.$emit('update:dataId', '')
        this.resetData()
      }
    },
    resetData () {
      this.workBigType = []
      this.workBigTypeId = []
      this.car = ''
      this.carId = ''
      this.driver = ''
      this.driverId = ''
      this.group = ''
      this.groupId = ''
      this.rule = ''
      this.ruleId = ''
      this.period = ''
      this.count = 2
      this.oilLoss = ''
      this.waterLoss = ''
      this.workTime = ''
      this.remark = ''
      this.status = 1
      this.carList = [];
      // this.$refs.carTreeSelect.resetTreeData();
      this.timeInit()
      this.currentIndex = 0
      this.endDateDisabled = false
      this.departmentName = ''
      this.departmentId = ''
      this.currentIndex = 0
      this.operationMode = ''
      this.carDisabled = true
    }
  }
}
</script>

<style lang="scss" scoped>
.menu {
  padding: 10px 20px;
  width: 130px;
  border-right: 1px solid #bbbbbb;
  div {
    line-height: 25px;
  }
  .current-menu {
    color: #25bb96;
  }
}
.item-menu {
  padding: 10px 20px;
  width: 100px;
  border-right: 1px solid #bbbbbb;
  div {
    line-height: 30px;
  }
  .current-menu {
    color: #25bb96;
  }
}
.car {
  width: 100%;
  height: 100px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 4px 7px;
  margin: 0 20px;
  cursor: pointer;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  .car-item {
    width: 100px;
    height: 24px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 6px 6px 0;
    padding-left: 4px;
    border: 1px solid #e1e4e6;
    border-radius: 3px;
  }
}
</style>