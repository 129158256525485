// 机械清洁-排班计划
<template>
  <div class="main"
       ref="main">
    <div class="topbar">
      <Tabs v-model="currentTabIndex"
            class="tabs">
        <TabPane v-for="item in tabArr"
                 :key="item.name"
                 :label="item.name"></TabPane>
        <!-- <Button type="text"
                slot="extra"
                v-if="currentTabIndex == 0"
                @click.stop="mechanicalCleanWorkTypeListVisible=true">设置作业类型>></Button> -->
      </Tabs>
      <div class="topbar-search">
        <div class="flex a-center j-between">
          <div class="flex">
            <template v-if="currentTabIndex===0">
              <Cascader :data="workBigTypeArr"
                        v-model="workBigType"
                        clearable
                        class="m-r-10"
                        style="width:160px"
                        change-on-select
                        placeholder="作业分类"></Cascader>
              <MyTreeSelect :value="departmentName"
                            placeholder="所属机构"
                            clearable
                            :data="departmentTreeArr"
                            class="m-r-10"
                            style="width:160px"
                            @onSelectChange="onChangeDepartment">
              </MyTreeSelect>
              <Select v-model="remoteCar"
                      filterable
                      clearable
                      placeholder="请输入车牌号"
                      :remote-method="remoteMethod"
                      :loading="remoteCarLoading"
                      class="m-r-10"
                      style="width:160px"
                      @on-query-change="onQueryChangeRemoteCar">
                <Option v-for="(option, index) in remoteCarList"
                        :value="option.id"
                        :key="option.id">{{option.name}}</Option>
              </Select>
              <!-- <MyTreeSelect :value="carNumber"
                            placeholder="车牌号"
                            clearable
                            class="m-r-10"
                            style="width:160px"
                            :data="carTreeArr"
                            search
                            checked
                            @onCheckChange="onChangeCarNumber">
              </MyTreeSelect> -->
              <Input v-model="planName"
                     placeholder="计划名称"
                     maxlength="30"
                     v-stringLimit
                     clearable
                     class="m-r-10"
                     style="width:160px" />
            </template>
            <template v-if="currentTabIndex===1">
              <Select v-model="teamGroupType"
                      clearable
                      placeholder="班组类型"
                      class="m-r-10"
                      style="width:160px">
                <Option v-for="item in teamGroupTypeArr"
                        :value="item.id"
                        :key="item.id">{{ item.name }}</Option>
              </Select>
              <Input v-model="teamGroupName"
                     clearable
                     placeholder="班组名称"
                     maxlength="30"
                     v-stringLimit
                     class="m-r-10"
                     style="width:160px" />
            </template>
            <template v-if="currentTabIndex===2">
              <Input v-model="ruleName"
                     clearable
                     placeholder="规则名称"
                     maxlength="30"
                     v-stringLimit
                     class="m-r-10"
                     style="width:160px" />
            </template>
          </div>
          <div class="flex">
            <Button type="primary"
                    class="m-l-10"
                    v-if="checkPermi(['/admin/mechanicalClean-crewScheduling/query'])"
                    @click.stop="onClickSearch">
              <Icon custom="i-icon icon-sousuo"
                    size="16"></Icon>
              查询
            </Button>
            <Button class="m-l-10"
                    v-if="checkPermi(['/admin/mechanicalClean-crewScheduling/query'])"
                    @click.stop="onClickReset">
              <Icon custom="i-icon icon-shuaxin"
                    size="16"></Icon>
              重置
            </Button>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="flex a-center j-between">
        <template v-if="currentTabIndex===0">
          <div class="flex">
            <Button type="primary"
                    class="m-r-10"
                    v-if="checkPermi(['/admin/mechanicalClean-crewScheduling/add'])"
                    @click.stop="onClickPlan">
              <Icon custom="i-icon icon-tianjia1"
                    size="16"></Icon>
              添加计划
            </Button>
            <Button type="primary"
                    class="m-r-10"
                    v-if="checkPermi(['/admin/mechanicalClean-crewScheduling/add'])"
                    @click.stop="gridVisible = true">
              <Icon custom="i-icon icon-tianjia1"
                    size="16"></Icon>
              添加网格
            </Button>
            <Button type="primary"
                    class="m-r-10"
                    v-if="checkPermi(['/admin/mechanicalClean-crewScheduling/add'])"
                    @click.stop="onClickPlanBatch">
              <Icon custom="i-icon icon-tianjia1"
                    size="16"></Icon>
              批量添加计划
            </Button>
            <Dropdown v-if="checkPermi(['/admin/mechanicalClean-crewScheduling/export'])">
              <Button class="m-r-10">
                <Icon custom="i-icon icon-shangchuan"
                      size="16"></Icon>
                导出
              </Button>
              <DropdownMenu slot="list">
                <DropdownItem name="part">
                  <ExportTemplate :path="getExportPath"
                                  :data="getExportData('part')">
                    <Button>
                      导出部分
                    </Button>
                  </ExportTemplate>
                </DropdownItem>
                <DropdownItem name="now">
                  <ExportTemplate :path="getExportPath"
                                  :data="getExportData('now')">
                    <Button>
                      导出本页
                    </Button>
                  </ExportTemplate>
                </DropdownItem>
                <DropdownItem name="all">
                  <ExportTemplate :path="getExportPath"
                                  :data="getExportData('all')">
                    <Button>
                      导出全部
                    </Button>
                  </ExportTemplate>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <ExportTemplate :path="getExportTemplatePath"
                            :data={type:1}
                            v-if="checkPermi(['/admin/mechanicalClean-crewScheduling/export'])">
              <Button class="m-r-10">
                <Icon custom="i-icon icon-shangchuan"
                      size="16"></Icon>
                导出模板
              </Button>
            </ExportTemplate>
            <ReaderFile @uploadSuccess="onClickReader"
                        accept=".xls,.xlsx"
                        :action='getReaderFileAction'
                        v-if="checkPermi(['/admin/mechanicalClean-crewScheduling/import'])"
                        :workType="1">
              <Button class="m-r-10">

                <Icon custom="i-icon icon-xiazai"
                      size="16"></Icon>
                导入
              </Button>
            </ReaderFile>
            <Dropdown @on-click="onClickBatch"
                      v-if="checkPermi(['/admin/mechanicalClean-crewScheduling/edit','/admin/mechanicalClean-crewScheduling/delete'])">
              <Button class="m-r-10">
                <Icon custom="i-icon icon-xiugai1"
                      size="16"></Icon>
                批量操作
              </Button>
              <DropdownMenu slot="list">
                <DropdownItem name="edit"
                              v-if="checkPermi(['/admin/mechanicalClean-crewScheduling/edit'])">批量修改</DropdownItem>
                <DropdownItem name="recover"
                              v-if="checkPermi(['/admin/mechanicalClean-crewScheduling/edit'])">批量启用</DropdownItem>
                <DropdownItem name="disable"
                              v-if="checkPermi(['/admin/mechanicalClean-crewScheduling/edit'])">批量禁用</DropdownItem>
                <DropdownItem name="delete"
                              v-if="checkPermi(['/admin/mechanicalClean-crewScheduling/delete'])">批量删除</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </template>
        <template v-if="currentTabIndex===1">
          <div class="flex">
            <Button type="primary"
                    class="m-r-10"
                    v-if="checkPermi(['/admin/mechanicalClean-crewScheduling/add'])"
                    @click.stop="workGroupVisible=true">
              <Icon custom="i-icon icon-tianjia1"
                    size="16"></Icon>
              添加
            </Button>
          </div>
        </template>
        <template v-if="currentTabIndex===2">
          <div class="flex">
            <Button type="primary"
                    class="m-r-10"
                    v-if="checkPermi(['/admin/mechanicalClean-crewScheduling/add'])"
                    @click.stop="workRuleVisible=true">
              <Icon custom="i-icon icon-tianjia1"
                    size="16"></Icon>
              添加
            </Button>
          </div>
        </template>
        <template v-if="currentTabIndex===0||currentTabIndex===1">
          <div class="flex a-center">
            <Icon custom="i-icon icon-tishi1"
                  size="16"
                  color="#FF9B77"></Icon>
            当前平台设置为{{summerTime}}至{{winterTime}}为夏季作息时间，{{winterTime}}为冬季作息时间
            <Button type="text"
                    class="m-l-5"
                    @click.stop="onClickTime">设置冬夏时间 >></Button>
          </div>
        </template>
      </div>
      <div class="flex">
        <div class="tree noScroll"
             :style="{height:tableHeight+50+'px'}"
             v-if="currentTabIndex===0">
          <MyTree :data="departmentTreeArr"
                  style="padding:0 10px"
                  @on-select-change="onSelectChangeDepart"></MyTree>
        </div>
        <div class="table">
          <template v-if="currentTabIndex===0">
            <Table ref="table"
                   :width="tableWidth"
                   :height="tableHeight"
                   :columns="getColumns"
                   :data="data"
                   border
                   stripe
                   :loading="tableLoading"
                   :row-class-name="rowClassName"
                   @on-selection-change="onChangeSelect"
                   @on-sort-change="onChangeSort">
              <template slot-scope="{ row }"
                        slot="operation">
                <Button type="text"
                        class="tableOperaBtn"
                        v-hasPermi="['/admin/mechanicalClean-crewScheduling/edit']"
                        @click.stop="onClickEditPlan(row)">修改</Button>
                <Button type="text"
                        class="tableOperaBtn"
                        v-hasPermi="['/admin/mechanicalClean-crewScheduling/edit']"
                        @click.stop="onClickStatusPlan(row)" v-if="row.isEnable==1">{{row.isEnable==1?'禁用':'恢复'}}</Button>
                <Button type="text"
                        class="tableOperaBtn"
                        v-hasPermi="['/admin/mechanicalClean-crewScheduling/delete']"
                        @click.stop="onClickDeletePlan(row)">删除</Button>
              </template>
            </Table>
          </template>
          <template v-else>
            <Table ref="table"
                   :height="tableHeight"
                   :columns="getColumns"
                   :data="data"
                   border
                   stripe
                   :loading="tableLoading"
                   :row-class-name="rowClassName"
                   @on-selection-change="onChangeSelect"
                   @on-sort-change="onChangeSort">
              <template slot-scope="{ row }"
                        slot="operation">
                <template v-if="currentTabIndex===1">
                  <Button type="text"
                          class="tableOperaBtn"
                          v-hasPermi="['/admin/mechanicalClean-crewScheduling/edit']"
                          @click.stop="onClickEditGroup(row)">修改</Button>
                  <Button type="text"
                          class="tableOperaBtn"
                          v-hasPermi="['/admin/mechanicalClean-crewScheduling/delete']"
                          @click.stop="onClickDeleteGroup(row)">删除</Button>
                </template>
                <template v-if="currentTabIndex===2">
                  <Button type="text"
                          class="tableOperaBtn"
                          v-hasPermi="['/admin/mechanicalClean-crewScheduling/edit']"
                          @click.stop="onClickEditRule(row)">修改</Button>
                  <Button type="text"
                          class="tableOperaBtn"
                          v-hasPermi="['/admin/mechanicalClean-crewScheduling/edit']"
                          @click.stop="onClickStatusRule(row)">{{row.isEnable==1?'禁用':'恢复'}}</Button>
                  <Button type="text"
                          class="tableOperaBtn"
                          v-hasPermi="['/admin/mechanicalClean-crewScheduling/delete']"
                          @click.stop="onClickDeleteRule(row)">删除</Button>
                </template>
              </template>
            </Table>
          </template>
          <Page class="m-t-10 m-l-10"
                :total="totalPage"
                :current="pageNum"
                :page-size="pageSize"
                show-total
                show-sizer
                show-elevator
                @on-change="onPageChange"
                @on-page-size-change="onPageSizeChange" />
        </div>
      </div>
    </div>

    <!-- 新增 / 排班 -->
    <MechanicalCleanPlanModal v-model="mechanicalCleanPlanVisible"
                              :dataId.sync="mechanicalCleanPlanId"
                              :workType.sync="mechanicalCleanPlanType"
                              @onClickConfirm="getList"
                              @onChange='onChangClearRowStyle'></MechanicalCleanPlanModal>

    <!-- 新增 / 修改作业班组 -->
    <WorkGroupModal v-model="workGroupVisible"
                    :dataId.sync="workGroupId"
                    :ruleType="3"
                    @onClickConfirm="getList"
                    @onChange='onChangClearRowStyle'></WorkGroupModal>

    <!-- 作业规则 -->
    <WorkRuleModal v-model="workRuleVisible"
                   :dataId.sync="workRuleId"
                   type="3"
                   @onClickConfirm="getList"
                   @onChange='onChangClearRowStyle'></WorkRuleModal>

    <!-- 新增 / 修改网格 -->
    <GridModal v-model="gridVisible"
               :width="drawerWidth"></GridModal>

    <!-- 设置冬夏时间 -->
    <WorkTimeModal v-model="workTimeVisible"
                   :dataId.sync="workTimeId"
                   @onClickConfirm="getTimeDetail"></WorkTimeModal>

    <!-- 设置作业类型 -->
    <!-- <MechanicalCleanWorkTypeListModal v-model="mechanicalCleanWorkTypeListVisible"></MechanicalCleanWorkTypeListModal> -->

    <!-- 表格中的绑定规则 -->
    <TableWorkRuleModal v-model="tableWorkRuleVisible"
                        :data.sync="tableWorkRuleData"
                        type="3"
                        @onChange='onChangClearRowStyle'></TableWorkRuleModal>

    <!-- 批量修改 -->
    <MechanicalCleanPlanBatchModal v-model="mechanicalCleanPlanBatchVisible"
                                   :dataId.sync="mechanicalCleanPlanBatchId"
                                   :workType.sync="mechanicalCleanPlanBatchType"
                                   @onClickConfirm="getList"></MechanicalCleanPlanBatchModal>
    <!-- 批量新增 / 排班 -->
    <MechanicalCleanPlanBatchAddModal v-model="mechanicalCleanPlanBatchAddVisible"
                                      :dataId.sync="mechanicalCleanPlanBatchAddId"
                                      :workType.sync="mechanicalCleanPlanBatchAddType"
                                      @onClickConfirm="getList"></MechanicalCleanPlanBatchAddModal>

    <!-- 新增计划前的类型弹框 -->
    <Modal v-model="planTypeVisible"
           title="选择类型"
           footer-hide
           @on-visible-change="onChangeVisible">
      <div class="plantype">
        <div @click.stop="onClickPlanType(0)">固定作业</div>
        <div @click.stop="onClickPlanType(1)">机动作业</div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import MyTree from '@/components/common/MyTree';
import MyTreeSelect from '@/components/common/MyTreeSelect';
import WorkGroupModal from '@/components/product/admin/modal/work/WorkGroupModal'
import WorkRuleModal from '@/components/product/admin/modal/work/WorkRuleModal'
import MechanicalCleanPlanModal from '@/components/product/admin/modal/work/MechanicalCleanPlanModal'
import MechanicalCleanPlanBatchAddModal from '@/components/product/admin/modal/work/MechanicalCleanPlanBatchAddModal'
import MechanicalCleanPlanBatchModal from '@/components/product/admin/modal/work/MechanicalCleanPlanBatchModal'
import WorkTimeModal from '@/components/product/admin/modal/work/WorkTimeModal'
import GridModal from '@/components/product/admin/modal/work/GridModal'
// import MechanicalCleanWorkTypeListModal from '@/components/product/admin/modal/work/MechanicalCleanWorkTypeListModal'
import TableWorkRuleModal from '@/components/product/admin/modal/work/TableWorkRuleModal'
import ExportTemplate from '@/components/common/ExportTemplate'
import ReaderFile from '@/components/common/ReaderFile'
import { checkPermi } from '@/utils/permission'
export default {
  components: {
    MyTree,
    MyTreeSelect,
    WorkGroupModal,
    WorkRuleModal,
    MechanicalCleanPlanModal,
    MechanicalCleanPlanBatchAddModal,
    MechanicalCleanPlanBatchModal,
    WorkTimeModal,
    GridModal,
    // MechanicalCleanWorkTypeListModal,
    TableWorkRuleModal,
    ExportTemplate,
    ReaderFile
  },
  data () {
    return {
      currentTabIndex: 0,
      tabArr: [
        {
          name: '排班计划'
        },
        {
          name: '作业班组'
        },
        {
          name: '作业规则'
        },
      ],
      workType: -1,
      workTypeArr: [
        {
          id: 0,
          name: '全部'
        },
        {
          id: 1,
          name: '扫地作业'
        },
        {
          id: 2,
          name: '洒水作业'
        },
      ],
      carNumber: '',
      carNumberId: '',
      planName: '',
      name: '',
      teamGroupType: -1,
      teamGroupTypeArr: [
        {
          id: 0,
          name: '全部'
        },
        {
          id: 1,
          name: '固定班'
        },
        {
          id: 2,
          name: '冬夏班'
        },
      ],
      teamGroupName: '',
      ruleName: '',
      date: '',
      departId: '',
      remoteCar: '',
      remoteCarList: [],
      remoteCarLoading: false,
      departmentName: '',
      departmentId: '',
      // 作业大类
      workBigType: [],
      summerTime: '',
      winterTime: '',
      totalPage: 0,
      pageNum: 1,
      pageSize: 20,
      tableWidth: 0,
      tableHeight: 0,
      tableLoading: false,
      columnsPlan: [
        {
          type: 'selection',
          width: 45,
          align: 'center',
          fixed: 'left',
        },
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '计划名称',
          key: 'planName',
          tooltip: true,
          width: 150,
          align: 'center',
          fixed: 'left',
        },
        {
          title: '计划类型',
          key: 'workType',
          tooltip: true,
          width: 80,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.workType == 2) {
              return h('span', '固定作业')
            } else {
              return h('span', '机动作业')
            }
          }
        },
        {
          title: '网格名称',
          key: 'gridName',
          tooltip: true,
          minWidth: 75,
          align: 'center'
        },
        {
          title: '机构名称',
          key: 'departmentName',
          tooltip: true,
          width: 110,
          align: 'center'
        },
        {
          title: '启用状态',
          key: 'isEnable',
          tooltip: true,
          width: 60,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.isEnable == 1) {
              return h('span', {
                style: {
                  color: '#25bb96',
                }
              }, '已启用')
            }
            return h('span', {
              style: {
                color: '#ff0000',
              }
            }, '已禁用')
          }
        },
        {
          title: '作业类型',
          key: 'workTypeName',
          tooltip: true,
          width: 70,
          align: 'center'
        },
        {
          title: '作业周期',
          tooltip: true,
          width: 70,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', [
              h('span', row.workCycle + '天')
            ]);
          }
        },
        {
          title: '作业车辆',
          key: 'carNumbers',
          tooltip: true,
          width: 70,
          align: 'center'
        },
        // {
        //   title: '驾驶员',
        //   key: 'driverNames',
        //   tooltip: true,
        //   width: 60,
        //   align: 'center'
        // },
        {
          title: '预计油耗(L)',
          key: 'expectOilConsumption',
          tooltip: true,
          width: 80,
          align: 'center'
        },
        {
          title: '预计水耗(T)',
          key: 'expectWaterConsumption',
          tooltip: true,
          width: 80,
          align: 'center'
        },
        {
          title: '趟次',
          key: 'workTrip',
          tooltip: true,
          width: 35,
          align: 'center'
        },
        {
          title: '有效期',
          tooltip: true,
          width: 160,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', [
              h('span', row.workStartDate + '~' + row.workEndDate)
            ]);
          }
        },
        {
          title: '车辆作业班组',
          key: 'workGroupName',
          tooltip: true,
          minWidth: 90,
          align: 'center'
        },
        {
          title: '作业规则',
          key: 'workRuleName',
          tooltip: true,
          width: 70,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', {
              style: {
                color: '#25bb96',
                cursor: 'pointer'
              },
              class: {
                line1: true
              },
              on: {
                click: () => {
                  this.onClickBindRule(row)
                }
              }
            }, row.workRuleName ? row.workRuleName : '点击绑定');
          }
        },
        {
          title: '备注',
          key: 'remark',
          tooltip: true,
          minWidth: 40,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          width: 130,
          align: 'center'
        }
      ],
      columnsTeamGroup: [
        {
          title: '序号',
          width: 60,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '班组名称',
          key: 'name',
          width: 120,
          tooltip: true,
          align: 'center',
          fixed: 'left',
        },
        {
          title: '所属机构',
          key: 'depName',
          width: 180,
          tooltip: true,
          align: 'center'
        },
        {
          title: '班组类型',
          key: 'kaoqinType',
          width: 100,
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', [
              h('span', row.kaoqinType == 1 ? '固定班' : '冬夏班')
            ]);
          }
        },
        {
          title: '作业时间段',
          key: 'managePhone',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            let str = ''
            if (row.kaoqins) {
              row.kaoqins.forEach((item, index) => {
                str = str + item.name + ' '
                if (item.sbsj && item.xbsj) {
                  str = str + '【' + item.sbsj + '~' + item.xbsj + '】'
                } else {
                  str = str + '【夏' + item.xsbsj + '~' + item.xxbsj + '】 【冬' + item.dsbsj + '~' + item.dxbsj + '】'
                }
                if (index == row.kaoqins.length - 1) {
                  str = str
                } else {
                  str = str + '，'
                }
              })
            }
            return h('span', str)
          }
        },
        {
          title: '备注',
          key: 'remark',
          width: 260,
          tooltip: true,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          width: 120,
          align: 'center'
        }
      ],
      columnsRule: [
        {
          title: '序号',
          width: 60,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '规则名称',
          key: 'ruleName',
          tooltip: true,
          width: 160,
          align: 'center',
          fixed: 'left',
        },
        {
          title: '启用状态',
          key: 'isEnable',
          tooltip: true,
          width: 160,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.isEnable == 1) {
              return h('span', {
                style: {
                  color: '#25bb96',
                }
              }, '已启用')
            }
            return h('span', {
              style: {
                color: '#ff0000',
              }
            }, '已禁用')
          }
        },
        {
          title: '规则详情',
          tooltip: true,
          minWidth: 120,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            let str = ''
            if (row.ruleItems) {
              let arr = row.ruleItems.map((item) => {
                let itemArr = []
                if (item.isEnable == 1) {
                  for (const i in item.content) {
                    if (i === 'scope') {
                      itemArr.push('范围' + item.content.scope + '米')
                    }
                    if (i === 'time') {
                      itemArr.push('时间' + item.content.time + '分钟')
                    }
                    if (i === 'yxc') {
                      itemArr.push('允许出' + item.content.yxc + '次')
                    }
                    if (i === 'dczc') {
                      itemArr.push('单次最长' + item.content.dczc + '分钟')
                    }
                    if (i === 'cwgpd') {
                      itemArr.push('出网格判定' + item.content.cwgpd + '分钟')
                    }
                    if (i === 'spwld') {
                      itemArr.push('扫盘未落地' + item.content.spwld + '分钟')
                    }
                    if (i === 'ptwkq') {
                      itemArr.push('喷头未开启' + item.content.ptwkq + '分钟')
                    }
                    if (i === 'tl') {
                      itemArr.push('停留' + item.content.tl + '分钟')
                    }
                    if (i === 'wdd') {
                      itemArr.push('未达到' + item.content.wdd + '%')
                    }
                    if (i === 'zx') {
                      itemArr.push('最小' + item.content.zx + 'km/h')
                    }
                    if (i === 'zd') {
                      itemArr.push('最大' + item.content.zd + 'km/h')
                    }
                    if (i === 'ccl') {
                      itemArr.push('超出量' + item.content.ccl + '%')
                    }
                    if (i === 'wd') {
                      itemArr.push('晚到' + item.content.wd + '分钟')
                    }
                  }
                }
                return itemArr.join()
              })
              arr = arr.filter(item => item)
              str = arr.join()
            }
            return h('span', str)
          }
        },
        {
          title: '备注',
          key: 'remark',
          tooltip: true,
          width: 160,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          width: 180,
          align: 'center'
        }
      ],
      data: [],
      selectArr: [],
      selectDataArr: [],
      selectIndexArr: [],
      selectVisitedArr: [],
      selectVisitedIndexArr: [],
      select: [],
      drawerWidth: 0,
      // 新增 / 修改 排班
      mechanicalCleanPlanVisible: false,
      mechanicalCleanPlanId: '',
      mechanicalCleanPlanType: null,
      // 批量新增 / 修改 排班
      mechanicalCleanPlanBatchAddVisible: false,
      mechanicalCleanPlanBatchAddId: '',
      mechanicalCleanPlanBatchAddType: null,
      // 新增 / 修改 作业班组
      workGroupVisible: false,
      workGroupId: '',
      // 新增 / 修改 作业规则
      workRuleVisible: false,
      workRuleId: '',
      // 新增 / 修改 网格
      gridVisible: false,
      // 设置冬夏时间
      workTimeVisible: false,
      workTimeId: null,
      // 设置作业类型
      mechanicalCleanWorkTypeListVisible: false,
      // 表格中的绑定规则
      tableWorkRuleVisible: false,
      tableWorkRuleData: {},
      // 新增计划前的弹框
      planTypeVisible: false,
      planTypeIndex: null,
      // 批量修改
      mechanicalCleanPlanBatchVisible: false,
      mechanicalCleanPlanBatchId: '',
      mechanicalCleanPlanBatchType: null,
      batch: false,     // 是否是批量新增
    };
  },
  watch: {
    currentTabIndex () {
      this.resetSearchData()
      this.onClickSearch()
    },
    selectDataArr (newVal) {
      this.selectIndexArr = []
      if (newVal.length > 0) {
        newVal.forEach(item => {
          let data = this.data.find((items, index) => {
            return item.id == items.id
          })
          this.selectIndexArr.push(data.id)
        })
      }
    },
    selectVisitedArr (newVal) {
      console.log(newVal);
      this.selectVisitedIndexArr = []
      if (newVal.length > 0) {
        newVal.forEach(item => {
          if (this.currentTabIndex === 0) {
            let data = this.data.find((items, index) => {
              return item.id == items.id
            })
            this.selectVisitedIndexArr = [data.id]
          }
          if (this.currentTabIndex === 1) {
            let data = this.data.find((items, index) => {
              return item.workGroupId == items.workGroupId
            })
            this.selectVisitedIndexArr = [data.workGroupId]
          }
          if (this.currentTabIndex === 2) {
            let data = this.data.find((items, index) => {
              return item.workRuleId == items.workRuleId
            })
            this.selectVisitedIndexArr = [data.workRuleId]
          }
        })
      }
    }
  },
  computed: {
    ...mapGetters({
      'departmentTreeArr': 'getDepartmentTreeList',
      'carTreeArr': 'getCarTreeList',
    }),
    getColumns () {
      let columns = []
      switch (this.currentTabIndex) {
        case 0:
          columns = this.columnsPlan
          break;
        case 1:
          columns = this.columnsTeamGroup
          break;
        case 2:
          columns = this.columnsRule
          break;
        default:
          break;
      }
      return columns
    },
    // 作业类型(级联)
    workBigTypeArr () {
      let arr = []
      let newArr = this.$store.getters.getCarWorkBigClassAllList.map(item => {
        item.value = item.id
        item.label = item.name
        item.children = item.itemlist
        item.children.map(subItem => {
          subItem.value = subItem.id
          subItem.label = subItem.name
          return subItem
        })
        return item
      })
      newArr.forEach(item => {
        if (item.name == '清扫作业' || item.name == '洒水作业') {
          arr.push(item)
        }
      })
      return arr
    },
    getExportPath () {
      let path = this.$http.exportCarPlan
      return path
    },
    getExportTemplatePath () {
      let path = ''
      switch (this.currentTabIndex) {
        case 0:
          path = this.$http.exportCarPlanTem
          break;
        default:
          break;
      }
      return path
    },
    getReaderFileAction () {
      let readerFileAction = []
      switch (this.currentTabIndex) {
        case 0:
          readerFileAction = '/hss/carPlan/carPlanExcelImport'
          break;
        default:
          break;
      }
      return readerFileAction
    }
  },
  mounted () {
    this.init()
  },
  destroyed () {
    window.onresize = null
  },
  methods: {
    checkPermi,
    ...mapActions([
      'updateDepartmentTreeList',
      'updateCarTreeList',
      'updateCarWorkBigClassAllList',
    ]),
    init () {
      this.tableWidth = window.innerWidth - 490
      this.tableHeight = window.innerHeight - 350
      window.onresize = () => {
        this.tableWidth = window.innerWidth - 490
        this.tableHeight = window.innerHeight - 350
      }
      this.drawerWidth = this.$refs.main.offsetWidth
      this.updateDepartmentTreeList()
      this.updateCarTreeList()
      this.updateCarWorkBigClassAllList()
      this.getList()
      this.getTimeDetail()
    },
    // 选择部门
    onSelectChangeDepart (section, data) {
      this.departId = data.id
      this.onClickSearch()
    },
    // 选择车牌号
    onChangeCarNumber (section) {
      let idArr = []
      let valueArr = []
      section.forEach(item => {
        if (item.type === 'car') {
          idArr.push(item.id)
          valueArr.push(item.name)
        }
      })
      this.carNumberId = idArr.join()
      this.carNumber = valueArr.join()
    },
    // 获取冬夏时间详情
    getTimeDetail () {
      this.$store.dispatch('getTimeCompleteDetail', 3).then(res => {
        let summer = res.summerDate.split('-')
        this.summerTime = summer[0] + '月' + summer[1] + '日'
        let winter = res.winterDate.split('-')
        this.winterTime = winter[0] + '月' + winter[1] + '日'
      })
    },
    // 点击设置冬夏时间
    onClickTime () {
      this.workTimeVisible = true
      this.workTimeId = 3
    },
    // 绑定规则
    onClickBindRule (row) {
      this.selectVisitedArr.push(row)
      this.tableWorkRuleVisible = true
      this.tableWorkRuleData = row
    },
    // 批量操作下拉菜单
    onClickBatch (name) {
      if (this.selectArr.length == 0) return this.$Message.info('请选择数据')
      switch (name) {
        case 'delete':
          console.log('delete');
          this.onDeletePlan(this.selectArr)
          break;
        // 禁用 
        case 'disable':
          this.$Modal.confirm({
            title: '禁用',
            content: '确认禁用?',
            onOk: () => {
              let params = {
                carPlanIdList: this.selectArr,
                isEnable: 2,
                type: 1
              }
              this.$http.updateCarPlanIsEnable(params).then((res) => {
                if (res.code == 200) {
                  if (res.result.message) {
                    this.$Message.info(res.result.message)
                  } else {
                    this.$Message.info(res.message)
                    this.getList()
                  }
                } else {
                  this.$Message.info(err.message)
                }
              })
            }
          })
          break;
        // 启用
        case 'recover':
          this.$Modal.confirm({
            title: '启用',
            content: '确认启用?',
            onOk: () => {
              let params = {
                carPlanIdList: this.selectArr,
                isEnable: 1,
                type: 1
              }
              this.$http.updateCarPlanIsEnable(params).then((res) => {
                if (res.code == 200) {
                  if (res.result.message) {
                    this.$Message.info(res.result.message)
                  } else {
                    this.$Message.info(res.message)
                    this.getList()
                  }
                } else {
                  this.$Message.info(err.message)
                }
              })
            }
          })
          break;
        case 'edit':
          let workType = this.selectDataArr[0].workType
          let boo = true
          this.selectDataArr.forEach(item => {
            if (item.workType != workType) {
              boo = false
            }
          })
          if (boo) {
            this.mechanicalCleanPlanBatchVisible = true
            this.mechanicalCleanPlanBatchId = this.selectArr.join()
            this.mechanicalCleanPlanBatchType = this.selectDataArr[0].workType
          } else {
            this.$Message.info('计划类型不同，无法进行批量修改')
          }
          break;
        default:
          break;
      }
    },
    // 修改所属机构
    onChangeDepartment (section) {
      this.departmentId = section.id
      this.departmentName = section.name
    },
    onChangeSelect (selection) {
      this.selectDataArr = selection
      switch (this.currentTabIndex) {
        case 0:
          this.selectArr = selection.map(item => item.id)
          break;
        case 1:
          this.selectArr = selection.map(item => item.workGroupId)
          break;
        case 2:
          this.selectArr = selection.map(item => item.workRuleId)
          break;
        default:
          break;
      }
    },
    onChangeSort (column, key, order) {

    },
    rowClassName (row, index) {
      if (this.currentTabIndex === 0) {
        if (this.selectVisitedIndexArr.includes(row.id)) {
          return 'table-visited-bgcolor'
        }
      }
      if (this.currentTabIndex === 1) {
        if (this.selectVisitedIndexArr.includes(row.workGroupId)) {
          return 'table-visited-bgcolor'
        }
      }
      if (this.currentTabIndex === 2) {
        if (this.selectVisitedIndexArr.includes(row.workRuleId)) {
          return 'table-visited-bgcolor'
        }
      }
      if (this.selectIndexArr.includes(row.id)) {
        return 'table-bgcolor'
      }
    },
    // 页数改变
    onPageChange (event) {
      this.pageNum = event
      this.getList()
    },
    // 每页条数改变
    onPageSizeChange (event) {
      this.pageSize = event
      this.getList()
    },
    getList () {
      this.selectIndexArr = []
      switch (this.currentTabIndex) {
        case 0:
          this.getPlanList()
          break;
        case 1:
          this.getGroupList()
          break;
        case 2:
          this.getRuleList()
          break;
        default:
          break;
      }
    },
    // 获取排班计划列表
    getPlanList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        workTypeId: this.workBigType[0] ? this.workBigType[0] : '',
        planName: this.planName,
        departmentId: this.departmentId,
        // carIds : this.remoteCar
        type: 1
      }
      if (this.remoteCar) {
        params.carIds = this.remoteCar.split(',')
      }
      this.tableLoading = true
      this.$http.getMechanicalPlanList(params).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          this.totalPage = res.result.total
          this.data = res.result.list
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 修改排班计划
    onClickEditPlan (row) {
      this.selectVisitedArr.push(row)
      this.mechanicalCleanPlanVisible = true
      this.mechanicalCleanPlanId = row.id
    },
    // 删除排班计划
    onClickDeletePlan (row) {
      this.selectVisitedArr.push(row)
      this.onDeletePlan([row.id])
    },
    onDeletePlan (arr) {
      this.$Modal.confirm({
        title: '删除',
        content: '确认删除?',
        onOk: () => {
          let params = {
            carPlanIdList: arr,
            type: 1
          }
          this.$http.deleteCarPlan(params).then(res => {
            if (res.code === 200) {
              this.onChangClearRowStyle()
              if (res.result.message) {
                this.$Message.info(res.result.message)
              } else {
                this.$Message.info(res.message)
                this.getList()
              }
            }
          })
        },
        onCancel: () => {
          this.onChangClearRowStyle()
        },
      })
    },
    // 修改排班计划状态
    onClickStatusPlan (row) {
      this.selectVisitedArr.push(row)
      if (row.isEnable == 1) {
        this.$Modal.confirm({
          title: '禁用',
          content: '确认禁用?',
          onOk: () => {
            let params = {
              carPlanIdList: [row.id],
              isEnable: 2,
              type: 1
            }
            this.$http.updateCarPlanIsEnable(params).then((res) => {
              if (res.code == 200) {
                this.onChangClearRowStyle()
                if (res.result.message) {
                  this.$Message.info(res.result.message)
                } else {
                  this.$Message.info(res.message)
                  this.getList()
                  
                }
              } else {
                this.$Message.info(err.message)
              }
            })
          },
          onCancel: () => {
            this.onChangClearRowStyle()
          },
        })
      } else {
        this.$Modal.confirm({
          title: '启用',
          content: '确认启用?',
          onOk: () => {
            let params = {
              carPlanIdList: [row.id],
              isEnable: 1,
              type: 1
            }
            this.$http.updateCarPlanIsEnable(params).then((res) => {
              if (res.code == 200) {
                this.onChangClearRowStyle()
                if (res.result.message) {
                  this.$Message.info(res.result.message)
                } else {
                  this.$Message.info(res.message)
                  this.getList()
                 
                }
              } else {
                this.$Message.info(err.message)
              }
            })
          },
          onCancel: () => {
            this.onChangClearRowStyle()
          },
        })
      }
    },
    // 获取班组列表
    getGroupList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        kaoqinType: this.teamGroupType == 0 ? '' : this.teamGroupType,
        name: this.teamGroupName,
        depid: "",
        module: 3
      }
      this.tableLoading = true
      this.$http.getWorkGroupList(params).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          this.totalPage = res.result.total
          this.data = res.result.workGroups
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 修改班组
    onClickEditGroup (row) {
      this.selectVisitedArr.push(row)
      this.workGroupVisible = true
      this.workGroupId = row.workGroupId
    },
    // 删除班组
    onClickDeleteGroup (row) {
      this.selectVisitedArr.push(row)
      this.$Modal.confirm({
        title: '删除',
        content: '确认删除?',
        onOk: () => {
          let params = {
            workGroupIdList: [row.workGroupId],
            module: 3
          }
          this.$http.deleteWorkGroupCar(params).then(res => {
            if (res.code === 200) {
              this.onChangClearRowStyle()
              if (res.result.workPlanIdList.length) {
                this.$Message.info({
                  content: '此班组已进行排班，删除会导致数据错误',
                  duration: 3
                })
              } else {
                this.getList()
                this.$Message.info({
                  content: res.message,
                  duration: 3
                })
              }
            }
          })
        },
        onCancel: () => {
          this.onChangClearRowStyle()
        },
      })
    },
    // 获取作业规则列表
    getRuleList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        name: this.ruleName,
        module: 3
      }
      this.tableLoading = true
      this.$http.getWorkRuleList(params).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          this.totalPage = res.result.total
          this.data = res.result.workRules
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 修改作业规则
    onClickEditRule (row) {
      this.selectVisitedArr.push(row)
      this.workRuleVisible = true
      this.workRuleId = row.workRuleId
    },
    // 修改作业规则状态
    onClickStatusRule (row) {
      this.selectVisitedArr.push(row)
      if (row.isEnable == 1) {
        this.$Modal.confirm({
          title: '禁用',
          content: '确认禁用?',
          onOk: () => {
            let params = {
              id: row.workRuleId,
              status: 0
            }
            this.$store.dispatch('createOrEditWorkRule', params).then(res => {
              this.getList()
              // this.$store.dispatch('updateCarTypeAllList', true)
            }).catch(err => {
              this.$Message.info(err.message)
            })
            this.onChangClearRowStyle()
          },
          onCancel: () => {
            this.onChangClearRowStyle()
          },
        })
      } else {
        this.$Modal.confirm({
          title: '启用',
          content: '确认启用?',
          onOk: () => {
            let params = {
              id: row.workRuleId,
              status: 1
            }
            this.$store.dispatch('createOrEditWorkRule', params).then(res => {
              this.getList()
              // this.$store.dispatch('updateCarTypeAllList', true)
            }).catch(err => {
              this.$Message.info(err.message)
            })
            this.onChangClearRowStyle()
          },
          onCancel: () => {
            this.onChangClearRowStyle()
          },
        })
      }
    },
    // 删除作业规则
    onClickDeleteRule (row) {
      this.selectVisitedArr.push(row)
      this.$Modal.confirm({
        title: '删除',
        content: '确认删除?',
        onOk: () => {
          let params = {
            workRuleIdList: [row.workRuleId],
            module: 3
          }
          this.$http.deleteWorkRuleCar(params).then(res => {
            if (res.code === 200) {
              if (res.result.workPlanIdList.length) {
                this.$Message.info({
                  content: '此规则已进行排班，删除会导致数据错误',
                  duration: 3
                })
              } else {
                this.getList()
                this.$Message.info({
                  content: res.message,
                  duration: 3
                })
              }
            }
          })
          this.onChangClearRowStyle()
        },
        onCancel: () => {
          this.onChangClearRowStyle()
        },
      })
    },
    // 车牌号远程搜索
    remoteMethod (query) {
      if (query !== '') {
        this.remoteCarLoading = true;
        let params = {
          onlineStatus: '',
          type: '',
          itemType: '',
          name: query,
          company: '',
        }
        this.$http.getMapSearchCarList(params).then((res) => {
          if (res.code === 200) {
            this.remoteCarLoading = false
            let data = res.result
            this.remoteCarList = data.filter(item => item.name.toLowerCase().indexOf(query.toLowerCase()) > -1);
          } else {
            this.$Message.info(res.message)
            this.remoteCarLoading = false
            this.remoteCarList = []
          }
        })
      } else {
        this.remoteCarList = []
      }
    },
    // 车牌号远程搜索框内容改变时
    onQueryChangeRemoteCar (value) {
      if (!value) {
        this.remoteCarList = []
      }
    },
    // 查询
    onClickSearch () {
      this.pageNum = 1
      this.getList()
    },
    // 点击重置
    onClickReset () {
      this.resetSearchData()
      this.onClickSearch()
    },
    onChangClearRowStyle () {
      this.selectVisitedArr = []

    },
    // 重置搜索条件
    resetSearchData () {
      // 计划
      this.planName = ''
      this.carNumber = ''
      this.carNumberId = ''
      this.workBigType = ''
      this.departmentId = ''
      this.departmentName = ''
      this.departId = ''
      this.remoteCar = ''
      // 班组
      this.teamGroupType = ''
      this.teamGroupName = ''
      // 规则
      this.ruleName = ''
    },
    onClickPlan () {
      this.batch = false
      this.planTypeVisible = true
    },
    onClickPlanBatch () {
      this.batch = true
      this.planTypeVisible = true
    },
    // 新增计划前的选择类型弹框
    onClickPlanType (index) {
      this.planTypeIndex = index
      if (this.planTypeIndex == 0) {
        this.mechanicalCleanPlanType = 2
        this.mechanicalCleanPlanBatchAddType = 2
      } else {
        this.mechanicalCleanPlanType = 1
        this.mechanicalCleanPlanBatchAddType = 1
      }
      if (this.batch) {
        this.mechanicalCleanPlanBatchAddVisible = true
      } else {
        this.mechanicalCleanPlanVisible = true
      }
      this.planTypeVisible = false
    },
    // 新增计划前的选择类型弹框确认按钮
    onClickPlanTypeConfirm () {
      if (this.planTypeIndex == 0) {
        this.mechanicalCleanPlanType = 1
      } else {
        this.mechanicalCleanPlanType = 2
      }
      this.mechanicalCleanPlanVisible = true
      this.planTypeVisible = false
    },
    onChangeVisible () {
      this.planTypeIndex = null
    },
    // 导出文件数据
    getExportData (value) {
      let data = {}
      switch (this.currentTabIndex) {
        case 0:
          data = {
            workTypeId: this.workBigType[0] ? this.workBigType[0] : '',
            planName: this.planName,
            departmentId: this.departId,
            type: 1
          }
          if (this.carNumberId) {
            data.carIds = this.carNumberId.split(',')
          }
          if (value == 'now') {
            data.ids = this.data.map(item => {
              return item.id
            })
          } else if (value == 'part') {
            data.ids = this.selectArr
          } else {
            // data.ids = []
          }
          break;
        default:
          break;
      }
      return data
    },
    // 导入回调
    onClickReader (arr) {
      if (arr.length > 0) {
        this.$Message.info('导入成功')
        this.getList()
      }
    },
  },
}
</script>

<style lang='scss' scoped>
@import "@/scss/iviewCssReset/adminTabs.scss";
::v-deep {
  .ivu-table .table-bgcolor td {
    background: #ebf7ff !important;
    border-right: 1px solid #ebf7ff;
  }
  .ivu-table .table-visited-bgcolor td {
    background: rgb(235, 247, 255) !important;
    border-right: 1px solid rgb(235, 247, 255);
  }
}
.main {
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: #f0f2f5;
  .topbar {
    background-color: #fff;
    .topbar-search {
      padding: 16px;
    }
  }
  .content {
    padding: 16px;
    background-color: #fff;
    margin-top: 16px;
    .tree {
      margin-top: 16px;
      width: 230px;
      margin-right: 16px;
      overflow-y: auto;
      flex-shrink: 0;
      border: 1px solid #e8eaec;
    }
    .table {
      margin-top: 16px;
      flex: 1;
    }
  }
}
.plantype {
  display: flex;
  div {
    flex: 1;
    line-height: 80px;
    text-align: center;
    border: 1px solid #e8eaec;
    font-size: 16px;
    cursor: pointer;
  }
  div:first-child {
    margin-right: 20px;
  }
  div:hover {
    border-color: #25bb96;
  }
}
</style>